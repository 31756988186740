import { useContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import Navbar from "./Navbar";
import Feedback from "./Feedback";
import { AppShell, Burger } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import Header from "./Header";

function PersistLogin() {
  const { auth, refresh } = useContext(AuthContext);
  const [opened, { toggle }] = useDisclosure();
  const [feedback, setFeedback] = useState({
    type: "",
    active: false,
    msg: "",
  });

  useEffect(() => {
    let id = -1;
    if (feedback.active) {
      id = setTimeout(() => {
        setFeedback({ type: "", active: false, msg: "" });
      }, 3000);
    }
    return () => {
      clearTimeout(id);
    };
  }, [feedback]);

  useEffect(() => {
    if (!auth.aT) refresh();
  }, [auth.aT, refresh]);

  if (!auth.aT) return;

  return (
    <>
      <AppShell
        classNames={{
          header:
            "flex items-center px-4 justify-between gap-5 !bg-headerBg !text-headerText",
          navbar: "!bg-headerBg !text-headerText",
          main: "bg-slate-100",
        }}
        header={{ height: 60 }}
        navbar={{
          width: 250,
          breakpoint: "sm",
          collapsed: { mobile: !opened },
        }}
        padding={{ base: "md", lg: "xl" }}
      >
        <AppShell.Header>
          <Burger
            opened={opened}
            onClick={toggle}
            hiddenFrom="sm"
            color="white"
            size="sm"
          />
          <Header />
        </AppShell.Header>

        <AppShell.Navbar p="md">
          <Navbar toggle={toggle} opened={opened} />
        </AppShell.Navbar>

        <AppShell.Main>
          <Outlet context={[feedback, setFeedback]} />
        </AppShell.Main>
      </AppShell>

      {feedback.active && (
        <Feedback feedback={feedback} setFeedback={setFeedback} />
      )}
    </>
  );
}

export default PersistLogin;
