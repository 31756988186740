import {
  API_URL,
  TRAILERS,
  TRUCKS,
  formatDate,
  formatName,
  isExpiredJWT,
  rutaFilter,
} from "../utils";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  MultiSelect,
  NumberInput,
  Select,
  TextInput,
} from "@mantine/core";
import axios from "axios";
import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import useFeedback from "../hooks/useFeedback";
import { TimeInput } from "@mantine/dates";
import useRute from "../hooks/useRute";

function UpdateTransportForm({ transport }) {
  const { auth, refresh } = useContext(AuthContext);
  const { state } = useLocation();
  const [price, setPrice] = useState(transport.price);
  const navigate = useNavigate();
  const setFeedback = useFeedback();
  const rute = useRute();
  const ruteArr = rute.map((ruta) => ruta.number);

  const errorCfg = {
    truck: { func: (v) => TRUCKS.includes(v), msg: "Numar camion invalid" },
    trailer: {
      func: (v) => TRAILERS.includes(v),
      msg: "Numar remorca invalid",
    },
    tur: { func: (v) => v > 0, msg: "Numar tur invalid" },
    ruta: {
      func1: (arr) => arr.every((val) => ruteArr.includes(val)),
      msg1: "Valoare ruta invalida",
      func2: (arr) => arr.length > 0,
      msg2: "Campul este obligatoriu",
    },
    kms: { func: (v) => v > 0, msg: "Numar kilometri invalid" },
    time: {
      func: (v) =>
        v !== "00:00" && /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(v),
      msg: "Durata invalida",
    },
    price: { func: (v) => v >= 0, msg: "Pret invalid" },
  };

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      date: formatDate(transport.date),
      added_by_user: formatName(transport.added_by_user.name),
      truck: transport.truck,
      trailer: transport.trailer,
      tur: transport.tur,
      ruta: transport.ruta.map((v) => v.toString()),
      kms: transport.kms,
      time: transport.time,
      price: transport.price,
    },
  });

  async function handleUpdate(data) {
    data.pending = false;
    data.added_by_user = transport.added_by_user._id;
    data.date = transport.date;
    data.ruta = data.ruta.map((val) => Number(val));
    const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
    try {
      await axios.post(
        API_URL + `/api/transport/${transport._id}/update`,
        data,
        { headers: { Authorization: `Bearer ${aT}` } }
      );
      setFeedback({
        type: "Success",
        active: true,
        msg: "Transportul a fost actualizat cu succes.",
      });
      navigate("/list", { state });
    } catch (err) {
      console.error(err);
      setFeedback({
        type: "Error",
        active: true,
        msg: "Transportul nu a putut fi actualizat.",
      });
    }
  }

  async function handleDelete() {
    if (window.confirm("Confirma STERGERE transport.")) {
      const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
      try {
        await axios.post(
          API_URL + `/api/transport/${transport._id}/delete`,
          {},
          { headers: { Authorization: `Bearer ${aT}` } }
        );
        setFeedback({
          type: "Success",
          active: true,
          msg: "Transportul a fost sters cu succes.",
        });
        navigate("/list", { state });
      } catch (err) {
        console.error(err);
        setFeedback({
          type: "Error",
          active: true,
          msg: "Transportul nu a putut fi sters.",
        });
      }
    }
  }

  async function updatePriceField(e) {
    let qs = "";

    e.forEach((v, i) => {
      qs += `ruta=${v}`;
      if (i < e.length - 1) qs += "&";
    });

    if (e.length) {
      let accessToken = auth.aT;
      if (isExpiredJWT(auth.user.exp)) accessToken = await refresh();

      try {
        const res = await axios.get(API_URL + "/api/price/?" + qs, {
          headers: { Authorization: `Bearer ${accessToken}` },
        });
        setPrice(res.data.price);
        setValue("price", res.data.price);
      } catch (err) {
        console.error(err);
      }
    } else {
      setPrice(null);
      setValue("price", null);
    }
  }

  return (
    <form method="post" className="max-w-[400px] flex flex-col gap-1">
      <Controller
        name="date"
        control={control}
        render={({ field }) => (
          <TextInput {...field} disabled label="Data" withAsterisk />
        )}
      />
      <Controller
        name="added_by_user"
        control={control}
        render={({ field }) => (
          <TextInput {...field} disabled label="Utilizator" withAsterisk />
        )}
      />
      <Controller
        name="truck"
        control={control}
        rules={{
          validate: (val) => errorCfg.truck.func(val) || errorCfg.truck.msg,
        }}
        render={({ field }) => (
          <Select
            {...field}
            label="Camion"
            placeholder="Alege camion..."
            error={errors.truck ? errors.truck.message : ""}
            data={TRUCKS}
            searchable
            withAsterisk
          />
        )}
      />
      <Controller
        name="trailer"
        control={control}
        rules={{
          validate: (val) => errorCfg.trailer.func(val) || errorCfg.trailer.msg,
        }}
        render={({ field }) => (
          <Select
            {...field}
            label="Remorca"
            placeholder="Alege remorca..."
            data={TRAILERS}
            error={errors.trailer ? errors.trailer.message : ""}
            searchable
            withAsterisk
          />
        )}
      />
      <Controller
        name="tur"
        control={control}
        rules={{
          validate: (val) => errorCfg.tur.func(val) || errorCfg.tur.msg,
        }}
        render={({ field }) => (
          <NumberInput
            {...field}
            label="Tur"
            error={errors.tur ? errors.tur.message : ""}
            placeholder="Numar tur..."
            min={1}
            withAsterisk
          />
        )}
      />
      <Controller
        name="ruta"
        control={control}
        rules={{
          validate: {
            validValue: (val) => errorCfg.ruta.func1(val) || errorCfg.ruta.msg1,
            notEmpty: (val) => errorCfg.ruta.func2(val) || errorCfg.ruta.msg2,
          },
        }}
        render={({ field }) => (
          <MultiSelect
            {...field}
            label="Ruta"
            error={errors.ruta ? errors.ruta.message : ""}
            placeholder="Adauga ruta..."
            data={ruteArr}
            searchable
            filter={rutaFilter}
            onChange={(e) => {
              updatePriceField(e);
              field.onChange(e);
            }}
            required
            clearable
            nothingFoundMessage="Ruta invalida..."
          />
        )}
      />
      <Controller
        name="kms"
        control={control}
        rules={{ validate: (v) => errorCfg.kms.func(v) || errorCfg.kms.msg }}
        render={({ field }) => (
          <NumberInput
            {...field}
            label="Kilometri"
            error={errors.kms ? errors.kms.message : ""}
            placeholder="Numar kilometri..."
            min={1}
            withAsterisk
          />
        )}
      />
      <Controller
        name="time"
        control={control}
        rules={{
          validate: (v) => errorCfg.time.func(v) || errorCfg.time.msg,
        }}
        render={({ field }) => (
          <TimeInput
            {...field}
            error={errors.time ? errors.time.message : ""}
            label="Durata"
            withAsterisk
          />
        )}
      />
      <Controller
        name="price"
        control={control}
        rules={{
          validate: (val) => errorCfg.price.func(val) || errorCfg.price.msg,
        }}
        render={({ field }) => (
          <NumberInput
            {...field}
            label="Pret"
            error={errors.price ? errors.price.message : ""}
            placeholder="Pret transport..."
            value={price || ""}
          />
        )}
      />
      <div className="flex justify-start gap-3 mt-2">
        <Button
          type="submit"
          color="green"
          onClick={handleSubmit(handleUpdate)}
        >
          {transport.pending === true ? "Accepta" : "Actualizeaza"}
        </Button>
        <Button color="red" onClick={handleSubmit(handleDelete)}>
          Sterge
        </Button>
      </div>
    </form>
  );
}

export default UpdateTransportForm;
