import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { API_URL, isExpiredJWT } from "../utils";
import axios from "axios";
import { FaEdit } from "react-icons/fa";
import { useDisclosure } from "@mantine/hooks";
import { Button, Modal, NumberInput, Table } from "@mantine/core";
import { Controller, useForm } from "react-hook-form";
import useFeedback from "../hooks/useFeedback";
import useRute from "../hooks/useRute";
import { useNavigate } from "react-router-dom";

const errorCfg = {
  number: { func: (v) => v > 0, msg: "Numar ruta invalid" },
  value: { func: (v) => v > 0, msg: "Numar pret invalid" },
};

function Rute() {
  const { auth, refresh } = useContext(AuthContext);
  const rute = useRute();
  const [opened, { open, close }] = useDisclosure(false);
  const [modalMode, setModalMode] = useState("");
  const setFeedback = useFeedback();
  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      number: 0,
      value: 0,
    },
  });

  async function handleCreate(data) {
    const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
    try {
      await axios.post(API_URL + `/api/rute/create`, data, {
        headers: { Authorization: `Bearer ${aT}` },
      });
      setFeedback({
        type: "Success",
        active: true,
        msg: "Ruta a fost adaugata cu succes.",
      });
      navigate("/rute");
      close();
    } catch (err) {
      console.error(err);
      setFeedback({
        type: "Error",
        active: true,
        msg: "Ruta nu a putut fi adaugata.",
      });
    }
  }

  async function handleUpdate(data) {
    const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
    try {
      await axios.post(API_URL + `/api/rute/${data.number}/update`, data, {
        headers: { Authorization: `Bearer ${aT}` },
      });
      setFeedback({
        type: "Success",
        active: true,
        msg: "Ruta a fost actualizata cu succes.",
      });
      navigate("/rute");
      close();
    } catch (err) {
      console.error(err);
      setFeedback({
        type: "Error",
        active: true,
        msg: "Ruta nu a putut fi actualizata.",
      });
    }
  }

  async function handleDelete(data) {
    if (window.confirm("Confirma STERGERE ruta.")) {
      const aT = isExpiredJWT(auth.user.exp) ? await refresh() : auth.aT;
      try {
        await axios.post(
          API_URL + `/api/rute/${data.number}/delete`,
          {},
          { headers: { Authorization: `Bearer ${aT}` } }
        );
        setFeedback({
          type: "Success",
          active: true,
          msg: "Ruta a fost stearsa cu succes.",
        });
        navigate("/rute");
        close();
      } catch (err) {
        console.error(err);
        setFeedback({
          type: "Error",
          active: true,
          msg: "Ruta nu a putut fi stearsa.",
        });
      }
    }
  }

  function openModal(number, value) {
    setValue("number", number);
    setValue("value", value);
    setModalMode("update");
    open();
  }

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Rute</h1>
      <Button
        onClick={() => {
          setValue("number", null);
          setValue("value", null);
          setModalMode("create");
          open();
        }}
        className="mb-4"
      >
        Adauga
      </Button>

      <div className="w-[500px]">
        <MantineTable rute={rute} openModal={openModal} />
      </div>

      <Modal
        lockScroll={false}
        opened={opened}
        onClose={close}
        title={modalMode === "create" ? "Adauga ruta" : "Modifica ruta"}
        classNames={{
          title: "!text-2xl !font-bold",
          body: "flex flex-col gap-4",
        }}
      >
        <form>
          <Controller
            name="number"
            control={control}
            rules={{
              validate: (val) =>
                errorCfg.number.func(val) || errorCfg.number.msg,
            }}
            render={({ field }) => (
              <NumberInput
                {...field}
                disabled={modalMode === "update" ? true : false}
                label="Numar ruta"
                placeholder="Ruta..."
                error={errors.number ? errors.number.message : ""}
                min={1}
                withAsterisk
              />
            )}
          />
          <Controller
            name="value"
            control={control}
            rules={{
              validate: (val) => errorCfg.value.func(val) || errorCfg.value.msg,
            }}
            render={({ field }) => (
              <NumberInput
                {...field}
                label="Pret"
                placeholder="Pret..."
                error={errors.value ? errors.value.message : ""}
                min={1}
                withAsterisk
              />
            )}
          />
          <div className="flex gap-4 mt-4 justify-center">
            <Button
              type="submit"
              onClick={
                modalMode === "create"
                  ? handleSubmit(handleCreate)
                  : handleSubmit(handleUpdate)
              }
              color="green"
            >
              {modalMode === "create" ? "Adauga" : "Modifica"}
            </Button>
            <Button
              type="submit"
              onClick={handleSubmit(handleDelete)}
              color="red"
            >
              Sterge
            </Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

export default Rute;

function MantineTable({ rute, openModal }) {
  const rows = rute.map((ruta) => (
    <Table.Tr key={ruta.number}>
      <Table.Td>
        <FaEdit
          className="text-xl cursor-pointer"
          onClick={() => {
            openModal(ruta.number, ruta.value);
          }}
        />
      </Table.Td>
      <Table.Td>{ruta.number}</Table.Td>
      <Table.Td>{ruta.value}</Table.Td>
    </Table.Tr>
  ));

  return (
    <Table.ScrollContainer minWidth={300}>
      <Table
        withTableBorder
        withColumnBorders
        highlightOnHover
        verticalSpacing={"sm"}
        classNames={{ thead: "!sticky" }}
      >
        <Table.Thead>
          <Table.Tr>
            <Table.Th className="w-[50px]">Edit</Table.Th>
            <Table.Th>Numar</Table.Th>
            <Table.Th>Pret</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>{rows}</Table.Tbody>
      </Table>
    </Table.ScrollContainer>
  );
}
