import { DateTime } from "luxon";

export function isExpiredJWT(exp) {
  return Date.now() - exp * 1000 >= 0;
}

export function formatName(name) {
  const [firstName, lastName] = name.split(".");
  return (
    firstName[0].toUpperCase() +
    firstName.substring(1) +
    " " +
    lastName[0].toUpperCase() +
    lastName.substring(1)
  );
}

export function revertName(formattedName) {
  return formattedName.split(" ").join(".").toLowerCase();
}

export function formatDate(date) {
  return DateTime.fromISO(date)
    .setZone("Europe/Bucharest")
    .setLocale("ro")
    .toLocaleString(DateTime.DATETIME_SHORT);
}

export const TRUCKS = [
  "B 101 TRL",
  "B 222 TRL",
  "B 333 TRL",
  "B 350 TRL",
  "B 444 TRL",
  "B 450 TRL",
  "B 550 TRL",
  "B 555 TRL",
  "B 650 TRL",
  "B 777 TRL",
  "B 999 TRL",
];

export const TRAILERS = [
  "B 130 TRL",
  "B 140 TRL",
  "B 230 TRL",
  "B 240 TRL",
  "B 260 TRL",
  "B 270 TRL",
  "B 280 TRL",
  "B 330 TRL",
  "B 430 TRL",
  "B 530 TRL",
  "B 630 TRL",
];

// export const API_URL = "https://safirtrans-transport-api.onrender.com";
export const API_URL =
  "https://safirtrans-transport-api-61fbb6304b19.herokuapp.com";
// export const API_URL = "http://192.168.0.179:8080";

export function rutaFilter({ options, search }) {
  return options.filter((option) => {
    return option.value.startsWith(search);
  });
}
