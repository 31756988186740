import axios from "axios";
import UpdateTransportForm from "./UpdateTransportForm";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { API_URL, isExpiredJWT } from "../utils";
import UpdateAdminoptsForm from "./UpdateAdminoptsForm";

function ShowTransport() {
  const { auth, refresh } = useContext(AuthContext);
  const { transportId } = useParams();
  const [transport, setTransport] = useState(null);
  const [adminopts, setAdminopts] = useState(null);

  useEffect(() => {
    async function getTransport() {
      try {
        const res = await Promise.all([
          axios.get(API_URL + `/api/transport/${transportId}`, {
            headers: { Authorization: `Bearer ${auth.aT}` },
          }),
          axios.get(API_URL + `/api/adminopts/${transportId}`, {
            headers: { Authorization: `Bearer ${auth.aT}` },
          }),
        ]);
        setTransport(res[0].data);
        setAdminopts(res[1].data);
      } catch (err) {
        console.error(err);
      }
    }

    if (auth.aT && !isExpiredJWT(auth.user.exp)) getTransport();
    else refresh();
  }, [auth, refresh, transportId]);

  return (
    <div>
      <h1 className="text-2xl font-bold mb-2">Vizualizare Transport</h1>
      {transport && <UpdateTransportForm transport={transport} />}
      {transport && <UpdateAdminoptsForm adminopts={adminopts} />}
    </div>
  );
}

export default ShowTransport;
